<template>
  <cv-data-table
    :columns="columns"
    :data="filteredData"
    :pagination="{ numberOfItems: this.data.length, pageSizes: [15, 50, 100] }"
    @pagination="onPagination"
    :zebra="false"
    ref="table"
  >
    <template
      slot="actions"
      v-if="tableActionsEnabled"
    >
      <cv-button-set>
        <cv-button
          small
          @click="addManually"
        >
          Add manually
        </cv-button>
        <router-link
          to="/transactions/import"
          custom
          v-slot="{ navigate }"
          v-if="shouldShowImportButton"
        >
          <cv-button
            small
            @click="navigate"
          >
            Import
          </cv-button>
        </router-link>
      </cv-button-set>
    </template>
    <template
      slot="data"
    >
      <cv-data-table-row
        v-for="(item, rowIndex) in filteredData"
        :key="`${rowIndex}`"
        :value="`${rowIndex}`"
      >
        <cv-data-table-cell>
          <span v-if="item.failure"><WarningAltFilled20 class="warning-icon" /></span>
        </cv-data-table-cell>
        <cv-data-table-cell v-if="!removeColumns.includes(0)">
          {{ formatDate(item.datetime) }}
        </cv-data-table-cell>
        <cv-data-table-cell v-if="!removeColumns.includes(1)">
          {{ item.type }}
        </cv-data-table-cell>
        <cv-data-table-cell v-if="!removeColumns.includes(2)">
          <router-link
            :to="`/assets/${item.assetId}`"
            custom
            v-slot="{ navigate, href }"
          >
            <a
              :href="href"
              @click="navigate"
            >{{ getAsset(item.assetId).name }}</a>
          </router-link>
        </cv-data-table-cell>
        <cv-data-table-cell v-if="!removeColumns.includes(3)">
          {{ formatFloat(item.quantity) }}
        </cv-data-table-cell>
        <cv-data-table-cell v-if="!removeColumns.includes(4)">
          {{ formatCurrency(localPrice(item)) }}
        </cv-data-table-cell>
        <cv-data-table-cell v-if="!removeColumns.includes(5)">
          {{ formatCurrency(localPrice(item) * item.quantity) }}
        </cv-data-table-cell>
        <cv-data-table-cell>
          <cv-overflow-menu
            label=""
          >
            <cv-overflow-menu-item @click="editTransaction(item)">
              Edit
            </cv-overflow-menu-item>
            <cv-overflow-menu-item
              danger
              @click="deleteTransaction(item)"
            >
              Delete
            </cv-overflow-menu-item>
          </cv-overflow-menu>
        </cv-data-table-cell>
      </cv-data-table-row>
    </template>
  </cv-data-table>
</template>

<script>
import {
  CvDataTable,
  CvButton,
  CvButtonSet,
  CvDataTableCell,
  CvDataTableRow,
  CvOverflowMenu,
  CvOverflowMenuItem,
} from '@carbon/vue';
import WarningAltFilled20 from '@carbon/icons-vue/es/warning--alt--filled/20';
import { mapGetters } from 'vuex';
import { formatCurrency, formatFloat, formatDate } from '../../lib/utils';
import { updateTransaction } from '@/lib/api';

export default {
  name: 'TransactionsTable',

  components: {
    CvDataTable,
    CvButton,
    CvButtonSet,
    CvDataTableCell,
    CvDataTableRow,
    CvOverflowMenu,
    CvOverflowMenuItem,
    WarningAltFilled20,
  },

  props: {
    data: {
      type: Array,
      requied: true,
      default: () => [],
    },
    tableActionsEnabled: {
      type: Boolean,
      default: () => true,
    },
    shouldShowImportButton: {
      type: Boolean,
      default: () => true,
    },
    removeColumns: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      defaultColumns: [
        '',
        'Date',
        'Action',
        'Asset',
        'Quantity',
        'Price per',
        'Cost basis',
        '',
      ],
      pageStart: 1,
      pageNumber: 1,
      pageLength: 15,
    };
  },

  computed: {
    ...mapGetters({
      getAsset: 'assets/get',
    }),

    filteredData() {
      return [...this.data]
        .sort((a, b) => b.datetime - a.datetime)
        .slice(this.pageStart - 1, this.pageStart + this.pageLength - 1);
    },

    columns() {
      return this.defaultColumns.filter((item, index) => (!this.removeColumns.includes(index)));
    },
  },

  methods: {
    onPagination({ start, page, length }) {
      this.pageNumber = page;
      this.pageStart = start;
      this.pageLength = length;
    },

    localPrice({ exchangeRate, price }) {
      console.log();
      return price * (1 / exchangeRate);
    },
    formatCurrency,
    formatFloat,
    formatDate,

    addManually() {
      this.$emit('add');
    },

    editTransaction(transaction) {
      this.$router.push(`/transactions/${transaction.id}/edit`);
    },

    async deleteTransaction({ id }) {
      try {
        const transaction = await updateTransaction(id, {
          isDeleted: true,
        });

        this.$store.dispatch('transactions/update', [transaction]);
        this.$store.dispatch('ledger/calculate');
        this.$toasts.success('Deleted transaction');
      } catch (error) {
        console.log(error);
        this.$toasts.error('Failed to delete transaction', { subTitle: error.message });
      }
    },
  },
};
</script>

<style lang="css">
.warning-icon {
  color: #da1e28;
}
</style>
