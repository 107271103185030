<template>
  <div>
    <div class="content-header">
      <h1>Transactions</h1>
    </div>

    <cv-inline-notification
      v-if="hasFailedTransactions"
      kind="error"
      title="Failed to match transactions"
      sub-title="Your buy and sell transactions don't match up."
      low-contrast
      hide-close-button
    />
    <transactions-table
      :data="transactions"
      @add="add"
    />
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import {
  CvInlineNotification,
} from '@carbon/vue';
import TransactionsTable from './TransactionsTable.vue';

export default {
  name: 'Transactions',

  components: {
    TransactionsTable,
    CvInlineNotification,
  },

  computed: {
    ...mapGetters({
      transactions: 'ledger/getAllTransactions',
    }),

    hasFailedTransactions() {
      return this.transactions.filter(({ failure }) => (!!failure)).length;
    },
  },

  methods: {
    add() {
      this.$router.push('/transactions/add');
    },
  },
};
</script>

<style lang="css">

</style>
